import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(() => {
        // Retrieve the theme preference from local storage
        const savedTheme = localStorage.getItem('isDarkMode');
        return savedTheme === 'true'; // Convert string to boolean
    });

    // Toggle the menu state
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    // Toggle dark mode
    const handleThemeToggle = () => {
        setIsDarkMode(prevMode => {
            const newMode = !prevMode;
            // Save the new theme preference to local storage
            localStorage.setItem('isDarkMode', newMode);
            return newMode;
        });
    };

    // Apply the class to the body based on the theme
    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark-mode');
        } else {
            document.body.classList.remove('dark-mode');
        }
    }, [isDarkMode]);

    return (
        <>
            <section className="header_sections">
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-3 col-sm-6 col-7 logoBackMobile">
                                <div className="logo_box">
                                    <Link to="/">
                                        <img
                                            className="img-fluid lightLogo"
                                            src={'/assets/images/logo.png'}
                                            alt="Logo"
                                        />
                                        <img
                                            className="img-fluid darkLogo"
                                            src={'/assets/images/darklogo.png'}
                                            alt="Logo"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-9 col-sm-6 col-5">
                                <div
                                    className={`nav_body ${
                                        menuOpen ? 'side_menu_show' : ''
                                    }`}
                                >
                                    <ul className="nav_contant">
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>
                                            <Link to="/about">About us</Link>
                                        </li>
                                        <li>
                                            <Link to="/service">Services & info</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact" className="btn-them-2">
                                                Contact us
                                            </Link>
                                        </li>
                                    </ul>
                                    <div
                                        className="menu_button_toggle"
                                        onClick={handleMenuToggle}
                                    >
                                        <i className="fa-solid fa-bars"></i>
                                    </div>
                                    <div
                                        className="menu_background"
                                        onClick={handleMenuToggle}
                                    >
                                        <i className="fa-solid fa-xmark"></i>
                                    </div>
                                    {/* Dark/Light Mode Toggle Button */}
                                    <button
                                        className="theme-toggle-button"
                                        onClick={handleThemeToggle}
                                    >
                                        {isDarkMode ? (
                                            <i className="fa-regular fa-sun"></i>
                                        ) : (
                                            <i className="fa-solid fa-sun"></i>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </section>
        </>
    );
}

export default Header;