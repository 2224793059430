import React, { Suspense, useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import NotFound from "../components/notFound/notFound";
// import { ToastContainer } from "react-toastify";
import Loader, { PageLoader } from "../components/loader/loader";
import Header from "./header";
import Footer from "./footer";

const Home = React.lazy(() => import("../pages/home"));
const Service = React.lazy(() => import("../pages/service"));
const About = React.lazy(() => import("../pages/about"));
const Contact = React.lazy(() => import("../pages/contact"));
const Privacy = React.lazy(() => import("../pages/privacy"));


function Layout() {

    return (
        <div
            className="main_body">
            {/* <ToastContainer /> */}
            <Header />
                {/* <Loader /> */}
                <div>
                    <Suspense fallback={<PageLoader />}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/service" element={<Service/>} /> 
                            <Route path="/about" element={<About/>} />
                            <Route path="/contact" element={<Contact/>} />
                            <Route path="/privacy" element={<Privacy/>} />

                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Suspense>
                </div>
            <Footer />
        </div>
    );
}

export default Layout;
