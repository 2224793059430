import React, { useContext, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
function Footer () {
    return (
        <>
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-12 col-sm-12">
                        <div className="foot_column">
                            <Link to="/" className="logo_box">
                                <img className="img-fluid" src={'/assets/images/foot-logo.png'} alt="#"/>
                            </Link>
                            <p>Elegance Elevated</p>                       
                        </div>
                                            
                    </div>
                    <div className="col-lg-9 col-md-12 col-sm-12">
                        <div className="foot_column">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About us</Link></li>
                                <li><Link to="/service">Services & info</Link></li>
                                <li><Link to="/contact">Contact us</Link></li>
                                <li><Link to="/privacy">Privacy policy</Link></li>
                            </ul>                                           
                        </div>                   
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="socil_Link">
                            <h5>Follow us on</h5>
                            <ul>
                                <li><Link to="#"><i className="fab fa-instagram"></i></Link></li>
                                <li><Link to="#"><i className="fab fa-facebook-f"></i></Link></li>
                                <li><Link to="#"><i className="fab fa-linkedin-in"></i></Link></li>
                                <li><Link to="#"><i className="fa-brands fa-threads"></i></Link></li>
                                <li><Link to="#"><i className="fa-brands fa-x-twitter"></i></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="copyright_box">
                            <p>All rights reserved © Calixta Salon</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </>
    );
}
export default Footer;